<template>
  <div
    v-if="loading"
    class="loading-page"
  >
    <img
      src="@/assets/images/loaders/rolling-loader.svg"
      alt="loader"
    >
  </div>
</template>

<script>
export default {
  props: {
    loading: {
      required: false,
      type: Boolean,
      default: true,
    },
  },
}
</script>

<style lang="sass" scoped>
  .loading-page
    position: fixed
    top: 0
    left: 0
    width: 100%
    height: 100%
    background: rgba(15, 47, 80, 0.2)
    text-align: center
    padding-top: 200px
    font-size: 30px
    font-family: sans-serif
    z-index: 99999

    & img
      position: absolute
      top: 50%
      left: 50%
      transform: translate(-50%, -50%)

</style>
